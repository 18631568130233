// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datarange_date_picker_container__Mamoz {
    position: relative;
}

.datarange_date_input__WJpy3 {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    font-size: 16px;
    width: 350px;
    text-align: center;
    background-color: white;
    cursor: pointer;
    position: relative;
}

.datarange_date_input__WJpy3 svg {
    position: absolute;
    right: 15px;
    color: rgb(119, 119, 119);
}

.datarange_calendar_container__VU0N5 {
    position: absolute;
    top: 50px;
    z-index: 1;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.datarange_date_display__C8tNw {
    margin-top: 10px;
    font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DateRange/datarange.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,uBAAuB;IACvB,wCAAwC;IACxC,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".date_picker_container {\n    position: relative;\n}\n\n.date_input {\n    padding: 10px;\n    border-radius: 5px;\n    border: 1px solid #ced4da;\n    font-size: 16px;\n    width: 350px;\n    text-align: center;\n    background-color: white;\n    cursor: pointer;\n    position: relative;\n}\n\n.date_input svg {\n    position: absolute;\n    right: 15px;\n    color: rgb(119, 119, 119);\n}\n\n.calendar_container {\n    position: absolute;\n    top: 50px;\n    z-index: 1;\n    background-color: white;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    border-radius: 5px;\n}\n\n.date_display {\n    margin-top: 10px;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date_picker_container": `datarange_date_picker_container__Mamoz`,
	"date_input": `datarange_date_input__WJpy3`,
	"calendar_container": `datarange_calendar_container__VU0N5`,
	"date_display": `datarange_date_display__C8tNw`
};
export default ___CSS_LOADER_EXPORT___;
