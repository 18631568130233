import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import saleSlice from "./slices/saleSlice";
import usersSlice from "./slices/usersSlice";
import replenishmentsSlice from "./slices/replenishmentsSlice";
import advertisingSlice from "./slices/advertisingSlice";
import affiliateSlice from "./slices/affiliateSlice";
import proxySlice from "./slices/proxySlice";
import apiSlice from "./slices/apiSlice";
import blogSlice from "./slices/blogSlice";
import freeTrialSlice from "./slices/freeTrialSlice";
import residentialSlice from "./slices/residentialSlice";
import mobileSlice from "./slices/mobileSlice";
import datacenterSlice from "./slices/datacenterSlice";
import privateSlice from "./slices/privateSlice";
import promocodeSlice from "./slices/promocodeSlice";

export const store = configureStore({
	reducer: {
		promocodeSlice,
		privateSlice,
		datacenterSlice,
		mobileSlice,
		residentialSlice,
		userSlice,
		saleSlice,
		usersSlice,
		replenishmentsSlice,
		advertisingSlice,
		affiliateSlice,
		proxySlice,
		apiSlice,
		blogSlice,
		freeTrialSlice,
	},
});
