import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './charStyle.css';

const CustomTooltipUsers = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const totalReg = payload[0].payload.totalReg;
    return (
      <div className="custom-tooltip">
        <p>{`Date: ${payload[0].payload.date}`}</p>
        <p>{`Verified Registrations: ${payload[1].value}`}</p>
        <p>{`Unverified Registrations: ${payload[0].value}`}</p>
        <p>{`Total Registrations: ${totalReg}`}</p>
      </div>
    );
  }

  return null;
};

const CustomTooltipTraffic = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`Date: ${label}`}</p>
        <p className="intro">{`Traffic: ${(payload[0].value).toFixed(2)}`}</p>
      </div>
    );
  }

  return null;
};



const BarChartComponent = ({ data, type }) => {

  const max = Math.max(...data.map(d => d.value));
  const normalizedData = data.map(d => ({
    ...d,
    value: (d.value / max) * 100,  // нормализуем значения
  }));

  if (type == 'Users') {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          barSize={70}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltipUsers />} />
          <Legend />
          <Bar dataKey="unverifiedReg" stackId="a" fill="#ffc658" />
          <Bar dataKey="verifiedReg" stackId="a" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    );
  } else {
    return (
      <ResponsiveContainer width="100%" height={300} style={{ marginBottom: '55px' }}>
      <BarChart
        data={normalizedData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        barSize={70}  // Уменьшение размера столбцов для лучшей видимости
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis domain={[0, 100]} /> {/* Отображение нормализованного диапазона от 0 до 100 */}
        <Tooltip content={<CustomTooltipTraffic />} />
        <Legend />
        <Bar dataKey="value" name="Traffic" fill="#82ca9c" />
      </BarChart>
    </ResponsiveContainer>
    )
  }
};

export default BarChartComponent;
