import React, { useEffect, useState } from "react";
import styles from "./Users.module.css";
import Split from "../../components/Split/Split";
import CreateIcon from "@mui/icons-material/Create";
import Modal from "../../components/Modal/Modal";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import BlueBtn from "../../components/BlueBtn/BlueBtn";
import OutLineBtn from "../../components/OutLineBtn/OutLineBtn";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuth } from "../../store/slices/userSlice";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
	banUnbanUser,
	changeBalanceUser,
	loadUsers,
	searchUsers,
	setStateLimit,
	setStatePage,
	setStateUpdate,
	updateUser,
} from "../../store/slices/usersSlice";
import CheckAuth from "../../components/CheckAuth";

const Users = () => {
	const usersState = useSelector((store) => store.usersSlice);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [order, setOrder] = useState({
		order: "id",
		order_type: "ASC",
	});

	const [activeModalEdit, setActiveModalEdit] = useState(false);
	const [activeModalEditSave, setActiveModalEditSave] = useState(false);
	const [activeModalEditSaveBan, setActiveModalEditSaveBan] = useState(false);

	const [alertEditSuccess, setAlertEditSuccess] = useState(false);
	const [alertCancelTextError, setAlertCancelTextError] = useState(false);
	const [alertBalanceTextError, setAlertBalanceTextError] = useState(false);

	const [activeModalBalance, setActiveModalBalance] = useState(false);

	const [infoBan, setInfoBan] = useState("");
	const [infoBalance, setInfoBanalce] = useState("");

	const [seleteced, setSelected] = useState({
		status: "",
		balance: 0,
		full_name: "",
		email: "",
		note: "",

		id: -1,
		statuses: [],
	});

	const [saveFirst, setSaveFirst] = useState(false);

	const [timerInput, setTimerInput] = useState(null);

	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	useEffect(() => {
		if (timerInput) {
			clearTimeout(timerInput);
		}

		const newTimeoutId = setTimeout(goSearch, 1500);
		setTimerInput(newTimeoutId);

		return () => {
			clearTimeout(newTimeoutId);
		};
	}, [searchText]);

	useEffect(() => {
		if (timerInput) {
			clearTimeout(timerInput);
		}

		const newTimeoutId = setTimeout(changeLimit, 1000);
		setTimerInput(newTimeoutId);

		return () => {
			clearTimeout(newTimeoutId);
		};
	}, [limit]);

	useEffect(() => {
		if (timerInput) {
			clearTimeout(timerInput);
		}

		const newTimeoutId = setTimeout(changePage, 1000);
		setTimerInput(newTimeoutId);

		return () => {
			clearTimeout(newTimeoutId);
		};
	}, [page]);

	useEffect(() => {
		let tmpState = seleteced.status == "Lock" ? true : false;
		if (saveFirst) {
			const defSale = usersState.users.find((s) => s.id === seleteced.id);

			if (
				tmpState == defSale.is_ban &&
				defSale.balance == seleteced.balance
			) {
				setActiveModalEditSave(true);
			} else if (tmpState != defSale.is_ban) {
				setActiveModalEditSaveBan(true);
			} else if (defSale.balance != seleteced.balance) {
				setActiveModalBalance(true);
			}

			setSaveFirst(false);
		}
	}, [saveFirst]);

	useEffect(() => {
		if (usersState.stateUpdate === "success") {
			dispatch(
				loadUsers({
					page: usersState.page,
					limit: usersState.limit,
					order,
				})
			);
			setAlertEditSuccess(true);
			dispatch(setStateUpdate("idle"));
		}
	}, [usersState.stateUpdate]);

	useEffect(() => {
		goSearch();
	}, [usersState.page, usersState.limit]);

	useEffect(() => {
		if (usersState.stateLoad == "error") {
			localStorage.removeItem("token");
			dispatch(setAuth({ auth: false, stateAuth: "error" }));
			navigate("/login");
		}
	}, [usersState.stateLoad]);

	useEffect(() => {
		goSearch();
	}, [order]);

	const goSearch = () => {
		if (searchText != "") {
			dispatch(
				searchUsers({
					page: usersState.page,
					limit: usersState.limit,
					searchText,
					order,
				})
			);
		} else {
			dispatch(
				loadUsers({
					page: usersState.page,
					limit: usersState.limit,
					order,
				})
			);
		}
	};

	const changeLimit = () => {
		if (!limit) {
			setLimit(1);
			dispatch(setStateLimit(1));
		} else if (limit <= 0) {
			setLimit(1);
			dispatch(setStateLimit(1));
		} else {
			dispatch(setStateLimit(limit));
		}
	};

	const changePage = () => {
		if (!page) {
			setPage(1);
			dispatch(setStatePage(1));
		} else if (page > usersState.pages) {
			setPage(usersState.pages);
			dispatch(setStatePage(usersState.pages));
		} else {
			dispatch(setStatePage(page));
		}
	};

	const saveBan = () => {
		if (infoBan != "") {
			const defSale = usersState.users.find((s) => s.id === seleteced.id);
			setActiveModalEditSaveBan(false);
			dispatch(banUnbanUser({ id: seleteced.id, note: infoBan }));
			setInfoBan("");

			if (defSale.balance != seleteced.balance) {
				setActiveModalBalance(true);
			} else {
				const defSale = usersState.users.find(
					(s) => s.id === seleteced.id
				);
				if (
					seleteced.note != defSale.note ||
					seleteced.email != defSale.email ||
					seleteced.full_name != defSale.full_name
				) {
					setActiveModalEditSave(true);
				}
			}
		} else {
			setAlertCancelTextError(true);
		}
	};

	const saveBalance = () => {
		if (infoBalance != "") {
			setActiveModalBalance(false);
			dispatch(
				changeBalanceUser({
					id: seleteced.id,
					note: infoBalance,
					balance: seleteced.balance,
				})
			);
			setInfoBanalce("");
			const defSale = usersState.users.find((s) => s.id === seleteced.id);
			if (
				seleteced.note != defSale.note ||
				seleteced.email != defSale.email ||
				seleteced.full_name != defSale.full_name
			) {
				setActiveModalEditSave(true);
			}
		} else {
			setAlertBalanceTextError(true);
		}
	};

	const save = () => {
		const defSale = usersState.users.find((s) => s.id === seleteced.id);
		if (
			seleteced.note != defSale.note ||
			seleteced.email != defSale.email ||
			seleteced.full_name != defSale.full_name
		) {
			dispatch(
				updateUser({
					id: seleteced.id,
					note: seleteced.note,
					email: seleteced.email,
					full_name: seleteced.full_name,
				})
			);
		} else {
			setAlertEditSuccess(true);
		}
		setActiveModalEditSave(false);
	};

	const clickSort = (type) => {
		if (order.order == type) {
			setOrder({
				...order,
				order_type: order.order_type == "ASC" ? "DESC" : "ASC",
			});
		} else {
			setOrder({ order: type, order_type: "ASC" });
		}
	};

	if (usersState.stateLoad == "success") {
		return (
			<CheckAuth>
				<div className={styles.users}>
					<h1>Users</h1>
					<Split />
					<div className={styles.tableSection}>
						<div className={styles.tableControllers}>
							<input
								type='text'
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
								placeholder='search'
							/>
						</div>
						<div className={styles.table}>
							<div className={styles.table_row}>
								<div>
									<SwapVertIcon
										onClick={() => clickSort("id")}
									/>{" "}
									ID
								</div>
								<div>
									<SwapVertIcon
										onClick={() => clickSort("full_name")}
									/>{" "}
									Full name
								</div>
								<div>
									<SwapVertIcon
										onClick={() => clickSort("email")}
									/>{" "}
									Mail
								</div>
								<div>
									<SwapVertIcon
										onClick={() =>
											clickSort("registration_date")
										}
									/>{" "}
									Date reg.
								</div>
								<div>
									<SwapVertIcon
										onClick={() =>
											clickSort("last_activity")
										}
									/>{" "}
									Date aut.
								</div>
								<div>Proxies</div>
								<div>Residential traffic</div>
								<div>
									<SwapVertIcon
										onClick={() =>
											clickSort("verification")
										}
									/>{" "}
									Verif
								</div>
								<div>
									<SwapVertIcon
										onClick={() => clickSort("balance")}
									/>{" "}
									Balance
								</div>
								<div>
									<SwapVertIcon
										onClick={() => clickSort("is_ban")}
									/>{" "}
									Status block
								</div>
								<div>Actions</div>
							</div>
							{usersState.users.map((user) => (
								<div className={styles.table_row}>
									<div>{user.id}</div>
									<div>{user.full_name}</div>
									<div>{user.email}</div>
									<div>{user.registration_date}</div>
									<div>{user.last_activity}</div>
									<div>{user.proxies}</div>
									<div>
										{user.residential_traffic
											? user.residential_traffic.toFixed(
													2
											  )
											: 0}
									</div>
									<div>
										{user.verification ? "Yes" : "Not"}
									</div>
									<div>${user.balance}</div>
									<div>{user.is_ban ? "Lock" : "Unlock"}</div>
									<div
										onClick={() => {
											setActiveModalEdit(true);
											setSelected({
												...user,
												statuses: user.is_ban
													? ["Lock", "Unlock"]
													: ["Unlock", "Lock"],
											});
										}}
									>
										<CreateIcon />
									</div>
								</div>
							))}
						</div>
						<div className={styles.table_pagination}>
							<div className={styles.table_pagination__rows}>
								<span>Rows per page</span>
								<input
									type='number'
									value={limit}
									onChange={(e) => setLimit(e.target.value)}
								/>
							</div>
							<div className={styles.table_pagination__pages}>
								<KeyboardArrowLeftIcon
									onClick={() =>
										setPage((p) => (p == 1 ? 1 : p - 1))
									}
								/>
								<input
									type='number'
									value={page}
									onChange={(e) => setPage(e.target.value)}
								/>
								<span>of</span>
								<span>{usersState.pages}</span>
								<KeyboardArrowRightIcon
									onClick={() =>
										setPage((p) =>
											p == usersState.pages ? p : p + 1
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<Modal
					active={activeModalEdit}
					off={() => setActiveModalEdit(false)}
				>
					<div
						className={styles.modalEdit}
						onClick={(e) => e.stopPropagation()}
					>
						<h2>Action</h2>
						<Split
							style={{ marginTop: "20px", marginBottom: "20px" }}
						/>
						<div>
							<span>Status lock</span>
							<select
								onChange={(e) => {
									setSelected({
										...seleteced,
										status: e.target.value,
									});
								}}
								value={seleteced.status}
							>
								{seleteced.statuses.map((s) => (
									<option value={s}>{s}</option>
								))}
							</select>
						</div>
						<div>
							<span>Balance</span>
							<input
								type='number'
								value={seleteced.balance}
								onChange={(e) =>
									setSelected({
										...seleteced,
										balance: e.target.value,
									})
								}
							/>
						</div>
						<div>
							<span>Full Name</span>
							<input
								type='text'
								value={seleteced.full_name}
								onChange={(e) =>
									setSelected({
										...seleteced,
										full_name: e.target.value,
									})
								}
							/>
						</div>
						<div>
							<span>Email</span>
							<input
								type='text'
								value={seleteced.email}
								onChange={(e) =>
									setSelected({
										...seleteced,
										email: e.target.value,
									})
								}
							/>
						</div>
						<div>
							<span>Note</span>
							<textarea
								onChange={(e) =>
									setSelected({
										...seleteced,
										note: e.target.value,
									})
								}
								value={seleteced.note ? seleteced.note : ""}
							></textarea>
						</div>
						<Split
							style={{ marginTop: "20px", marginBottom: "20px" }}
						/>
						<div className={styles.modalEdit__btns}>
							<OutLineBtn
								text={"Cancel"}
								onClick={() => setActiveModalEdit(false)}
							/>
							<BlueBtn
								text={"Save"}
								onClick={() => {
									setActiveModalEdit(false);
									setSaveFirst(true);
								}}
							/>
						</div>
					</div>
				</Modal>
				<Modal
					active={activeModalEditSave}
					off={() => setActiveModalEditSave(false)}
				>
					<div
						className={styles.modalEdit}
						onClick={(e) => e.stopPropagation()}
					>
						<h2 style={{ marginBottom: "30px", marginTop: "20px" }}>
							Are you sure to save the changes ?
						</h2>
						<div className={styles.modalEdit__btns}>
							<OutLineBtn
								text={"Cancel"}
								onClick={() => setActiveModalEditSave(false)}
							/>
							<BlueBtn text={"Save"} onClick={save} />
						</div>
					</div>
				</Modal>
				<Modal
					active={activeModalEditSaveBan}
					off={() => setActiveModalEditSaveBan(false)}
				>
					<div
						className={styles.modalEdit}
						onClick={(e) => e.stopPropagation()}
					>
						<h2 style={{ marginBottom: "30px", marginTop: "20px" }}>
							Enter the reason for the lock/unlock
						</h2>
						<div className={styles.modalEdit__enter}>
							<span>Note</span>
							<textarea
								value={infoBan}
								onChange={(e) => setInfoBan(e.target.value)}
							></textarea>
						</div>
						<div className={styles.modalEdit__btns}>
							<OutLineBtn
								text={"Cancel"}
								onClick={() => {
									setActiveModalEditSaveBan(false);
									setActiveModalEditSave(true);
								}}
							/>
							<BlueBtn text={"Save"} onClick={saveBan} />
						</div>
					</div>
				</Modal>
				<Modal
					active={activeModalBalance}
					off={() => setActiveModalBalance(false)}
				>
					<div
						className={styles.modalEdit}
						onClick={(e) => e.stopPropagation()}
					>
						<h2 style={{ marginBottom: "30px", marginTop: "20px" }}>
							Enter the reason for the balance change
						</h2>
						<div className={styles.modalEdit__enter}>
							<span>Note</span>
							<textarea
								value={infoBalance}
								onChange={(e) => setInfoBanalce(e.target.value)}
							></textarea>
						</div>
						<div className={styles.modalEdit__btns}>
							<OutLineBtn
								text={"Cancel"}
								onClick={() => {
									setActiveModalBalance(false);
									setActiveModalEditSave(true);
								}}
							/>
							<BlueBtn text={"Save"} onClick={saveBalance} />
						</div>
					</div>
				</Modal>
				<Snackbar
					open={alertEditSuccess}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={5000}
					onClose={() => setAlertEditSuccess(false)}
				>
					<Alert
						onClose={() => setAlertEditSuccess(false)}
						severity='success'
					>
						Data updated successfully !
					</Alert>
				</Snackbar>
				<Snackbar
					open={alertCancelTextError}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={5000}
					onClose={() => setAlertCancelTextError(false)}
				>
					<Alert
						onClose={() => setAlertCancelTextError(false)}
						severity='error'
					>
						Enter the reason for the lock/unlock !
					</Alert>
				</Snackbar>
				<Snackbar
					open={alertBalanceTextError}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={5000}
					onClose={() => setAlertBalanceTextError(false)}
				>
					<Alert
						onClose={() => setAlertBalanceTextError(false)}
						severity='error'
					>
						Enter the reason for the balance change !
					</Alert>
				</Snackbar>
			</CheckAuth>
		);
	} else return "";
};

export default Users;
