import React from "react";
import CardsList from "../../components/CardsList/CardsList";
import styles from "./Home.module.css";
import Split from "../../components/Split/Split";
import CheckAuth from "../../components/CheckAuth";

const Home = () => {
	return (
		<CheckAuth>
			<div className={styles.home}>
				<h1>Dashboard</h1>
				<Split />
				<CardsList />
			</div>
		</CheckAuth>
	);
};

export default Home;
