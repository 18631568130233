import React, {useEffect, useState} from 'react'
import styles from './advertisingLinks.module.css'
import Split from '../../components/Split/Split'
import CreateIcon from '@mui/icons-material/Create';
import Modal from '../../components/Modal/Modal';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import BlueBtn from '../../components/BlueBtn/BlueBtn';
import OutLineBtn from '../../components/OutLineBtn/OutLineBtn';
import {Alert,Snackbar} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuth } from '../../store/slices/userSlice';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { loadAdvertising, searchAdvertising, setStateLimit, setStatePage, setStateUpdate, updateAdvertising,setStateCreate,createAdvertising, deleteAdvertising } from '../../store/slices/advertisingSlice';
import CheckAuth from '../../components/CheckAuth';

const AdvertisingLinks = () => {

    const [order ,setOrder] = useState({
        order: 'id',
        order_type: 'ASC'
    })

    const advertisingState = useSelector((store)=>store.advertisingSlice)
    
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [activeModalEdit, setActiveModalEdit] = useState(false);
    const [activeModalEditSave, setActiveModalEditSave] = useState(false); 
    const [activeModalEditSaveCancel, setActiveModalEditSaveCancel] = useState(false);

    const [alertEditSuccess, setAlertEditSuccess] = useState(false);
    const [alertCancelTextError, setAlertCancelTextError] = useState(false);

    const [infoDelete, setInfoDelete] = useState("")

    const [seleteced, setSelected] = useState({
        status: "",
        description: "",
        name:"",
        id: -1,
        statuses: []
    })
    

    const [saveFirst,setSaveFirst] = useState(false)

    const [timerInput, setTimerInput] = useState(null);
    
    const [searchText, setSearchText] = useState('');
    const [page,setPage] = useState(1);
    const [limit,setLimit] = useState(10);

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(goSearch, 1500);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [searchText]); 

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changeLimit, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [limit]); 

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changePage, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [page]); 

    useEffect(()=>{
        if (saveFirst) {
            const defSale = advertisingState.links.find(s => s.id === seleteced.id)

            if (seleteced.status != defSale.status) {
                setActiveModalEditSaveCancel(true)
            } else {
                setActiveModalEditSave(true)
            }

            setSaveFirst(false)
        }
    },[saveFirst])


    useEffect(()=>{
        if (advertisingState.stateUpdate === 'success') {
            dispatch(loadAdvertising({page:advertisingState.page,limit:advertisingState.limit, order}))
            setAlertEditSuccess(true)
            dispatch(setStateUpdate('idle'))
            
        } 
    },[advertisingState.stateUpdate])


    useEffect(()=>{
        goSearch()
    },[advertisingState.page, advertisingState.limit])


    useEffect(()=>{
        if (advertisingState.stateLoad == 'error') {
            localStorage.removeItem('token');
            dispatch(setAuth({auth:false,stateAuth:'error'}))
            navigate('/login')
        }
    },[advertisingState.stateLoad])

    useEffect(()=>{
        goSearch()
    },[order])

    const goSearch = () => {
        if(searchText != "") {
            dispatch(searchAdvertising({page:advertisingState.page,limit:advertisingState.limit,searchText,order}))
        } else {
            dispatch(loadAdvertising({page:advertisingState.page,limit:advertisingState.limit,order}))
        }
    };

    const changeLimit = () => {
        if (!limit) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else if (limit <= 0) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else {
            dispatch(setStateLimit(limit))
        }
    }

    const changePage = () => {
        if (!page) {
            setPage(1)
            dispatch(setStatePage(1))
        } else if (page > advertisingState.pages) {
            setPage(advertisingState.pages)
            dispatch(setStatePage(advertisingState.pages))
        } else {
            dispatch(setStatePage(page))
        }
    }


    const saveCancel = () => {
        if (infoDelete != '') {
            dispatch(updateAdvertising({id:seleteced.id,note:seleteced.note, status:seleteced.status.toLowerCase(), description:infoDelete}))
            setActiveModalEditSaveCancel(false)
        } else {
            setAlertCancelTextError(true)
        }
    }

    const save = () => {
        const defSale = advertisingState.links.find(s => s.id === seleteced.id)
        dispatch(updateAdvertising({id:seleteced.id, name:seleteced.name, description:seleteced.description, status:seleteced.status.toLowerCase()}))
        setActiveModalEditSave(false)
        // if (seleteced.note != defSale.note) {
        // } else {
        //     setAlertEditSuccess(true)
        // }
    }

    const clickSort = (type) => {
        if (order.order == type) {
            setOrder({...order,order_type: order.order_type == 'ASC' ? 'DESC' : "ASC"})
        } else {
            setOrder({order:type, order_type:'ASC'})
        }
    }

    const defaultInfoCreate = {
        name: '',
        description: ''
    }

    const [modalCreate,setModalCreate] = useState(false)
    const [infoCreate,setInfoCreate] = useState(defaultInfoCreate)
    const [errorCreate, setErrorCreate] = useState(false)
    const [sucessCreate, setSuccessCreate] = useState(false)
    const [sucessDelete, setSuccessDelete] = useState(false)

    const createLink = () => {
        if (infoCreate.name.length < 1) {
            setErrorCreate(true)
        } else {
            setInfoCreate(defaultInfoCreate)
            dispatch(createAdvertising({name: infoCreate.name, description: infoCreate.description}))
            setModalCreate(false)
        }
    }

    useEffect(()=>{
        if (advertisingState.stateCreate == 'success') {
            setSuccessCreate(true)
            dispatch(setStateCreate('idle'))
            dispatch(loadAdvertising({page:advertisingState.page,limit:advertisingState.limit, order}))
        }
    },[advertisingState.stateCreate])

    const deleteLink = (id) => {
        dispatch(deleteAdvertising(id))
        setSuccessDelete(true)
        setActiveModalEdit(false)
    }

    if (advertisingState.stateLoad == 'success') {
        return (
			<CheckAuth>
				<div className={styles.users}>
					<h1>Advertising Links</h1>
					<Split />
					<div className={styles.tableSection}>
						<div className={styles.tableControllers}>
							<BlueBtn
								text={"Create"}
								onClick={() => setModalCreate(true)}
							/>
							<input
								type='text'
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
								placeholder='search'
							/>
						</div>
						<div className={styles.table}>
							<div className={styles.table_row}>
								<div>ID</div>
								<div> Name</div>
								<div> Description</div>
								<div> Link</div>
								<div> Date</div>
								<div> Users</div>
								<div> Sum</div>
								<div> Status</div>
								<div>Actions</div>
							</div>
							{advertisingState.links.map((link) => (
								<div className={styles.table_row}>
									<div>{link.id}</div>
									<div>{link.name}</div>
									<div>{link.description}</div>
									<div>{link.code}</div>
									<div>{link.create}</div>
									<div>{link.count}</div>
									<div>${link.balance}</div>
									<div>{link.status}</div>
									<div
										onClick={() => {
											setActiveModalEdit(true);
											setSelected({
												...link,
												statuses:
													link.status === "Inactive"
														? ["Inactive", "Active"]
														: [
																"Active",
																"Inactive",
														  ],
											});
										}}
									>
										<CreateIcon />
									</div>
								</div>
							))}
						</div>
						<div className={styles.table_pagination}>
							<div className={styles.table_pagination__rows}>
								<span>Rows per page</span>
								<input
									type='number'
									value={limit}
									onChange={(e) => setLimit(e.target.value)}
								/>
							</div>
							<div className={styles.table_pagination__pages}>
								<KeyboardArrowLeftIcon
									onClick={() =>
										setPage((p) => (p == 1 ? 1 : p - 1))
									}
								/>
								<input
									type='number'
									value={page}
									onChange={(e) => setPage(e.target.value)}
								/>
								<span>of</span>
								<span>{advertisingState.pages}</span>
								<KeyboardArrowRightIcon
									onClick={() =>
										setPage((p) =>
											p == advertisingState.pages
												? p
												: p + 1
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<Modal
					active={modalCreate}
					off={() => {
						setModalCreate(false);
						setInfoCreate(defaultInfoCreate);
					}}
				>
					<div
						className={styles.modalEdit}
						onClick={(e) => e.stopPropagation()}
					>
						<h2>Create</h2>
						<Split
							style={{ marginTop: "20px", marginBottom: "20px" }}
						/>
						<div>
							<span>Name</span>
							<input
								onChange={(e) =>
									setInfoCreate({
										...infoCreate,
										name: e.target.value,
									})
								}
								value={infoCreate.name}
							></input>
						</div>
						<div>
							<span>Description</span>
							<textarea
								onChange={(e) =>
									setInfoCreate({
										...infoCreate,
										description: e.target.value,
									})
								}
								value={infoCreate.description}
							></textarea>
						</div>
						<Split
							style={{ marginTop: "20px", marginBottom: "20px" }}
						/>
						<div className={styles.modalEdit__btns}>
							<OutLineBtn
								text={"Cancel"}
								onClick={() => {
									setModalCreate(false);
									setInfoCreate(defaultInfoCreate);
								}}
							/>
							<BlueBtn text={"Save"} onClick={createLink} />
						</div>
					</div>
				</Modal>
				<Modal
					active={activeModalEdit}
					off={() => setActiveModalEdit(false)}
				>
					<div
						className={styles.modalEdit}
						onClick={(e) => e.stopPropagation()}
					>
						<h2>Action</h2>
						<Split
							style={{ marginTop: "20px", marginBottom: "20px" }}
						/>
						<div>
							<span>Name</span>
							<textarea
								onChange={(e) =>
									setSelected({
										...seleteced,
										name: e.target.value,
									})
								}
								value={seleteced.name}
							></textarea>
						</div>
						<div>
							<span>Status</span>
							<select
								onChange={(e) => {
									setSelected({
										...seleteced,
										status: e.target.value,
									});
								}}
								value={seleteced.status}
							>
								{seleteced.statuses.map((s) => (
									<option value={s}>{s}</option>
								))}
							</select>
						</div>
						<div>
							<span>Note</span>
							<textarea
								onChange={(e) =>
									setSelected({
										...seleteced,
										description: e.target.value,
									})
								}
								value={seleteced.description}
							></textarea>
						</div>
						<Split
							style={{ marginTop: "20px", marginBottom: "20px" }}
						/>
						<div className={styles.modalEdit__btns_v2}>
							<BlueBtn
								text={"Delete"}
								onClick={() => deleteLink(seleteced.id)}
								style={{
									backgroundColor: "red",
									border: "2px solid red",
								}}
							/>
							<div>
								<OutLineBtn
									text={"Cancel"}
									onClick={() => setActiveModalEdit(false)}
								/>
								<BlueBtn
									text={"Save"}
									onClick={() => {
										setActiveModalEdit(false);
										setActiveModalEditSave(true);
									}}
								/>
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					active={activeModalEditSave}
					off={() => setActiveModalEditSave(false)}
				>
					<div
						className={styles.modalEdit}
						onClick={(e) => e.stopPropagation()}
					>
						<h2 style={{ marginBottom: "30px", marginTop: "20px" }}>
							Are you sure to save the changes ?
						</h2>
						<div className={styles.modalEdit__btns}>
							<OutLineBtn
								text={"Cancel"}
								onClick={() => setActiveModalEditSave(false)}
							/>
							<BlueBtn text={"Save"} onClick={save} />
						</div>
					</div>
				</Modal>
				<Modal
					active={activeModalEditSaveCancel}
					off={() => setActiveModalEditSaveCancel(false)}
				>
					<div
						className={styles.modalEdit}
						onClick={(e) => e.stopPropagation()}
					>
						<h2 style={{ marginBottom: "30px", marginTop: "20px" }}>
							Enter the reason for cancellation
						</h2>
						<div className={styles.modalEdit__enter}>
							<span>Note</span>
							<textarea
								value={infoDelete}
								onChange={(e) => setInfoDelete(e.target.value)}
							></textarea>
						</div>
						<div className={styles.modalEdit__btns}>
							<OutLineBtn
								text={"Cancel"}
								onClick={() =>
									setActiveModalEditSaveCancel(false)
								}
							/>
							<BlueBtn text={"Save"} onClick={saveCancel} />
						</div>
					</div>
				</Modal>
				<Snackbar
					open={alertEditSuccess}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={5000}
					onClose={() => setAlertEditSuccess(false)}
				>
					<Alert
						onClose={() => setAlertEditSuccess(false)}
						severity='success'
					>
						Data updated successfully !
					</Alert>
				</Snackbar>
				<Snackbar
					open={alertCancelTextError}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={5000}
					onClose={() => setAlertCancelTextError(false)}
				>
					<Alert
						onClose={() => setAlertCancelTextError(false)}
						severity='error'
					>
						Enter the reason for cancellation !
					</Alert>
				</Snackbar>
				<Snackbar
					open={errorCreate}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={5000}
					onClose={() => setErrorCreate(false)}
				>
					<Alert
						onClose={() => setErrorCreate(false)}
						severity='error'
					>
						You didn't specify a link name !
					</Alert>
				</Snackbar>
				<Snackbar
					open={sucessCreate}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={5000}
					onClose={() => setSuccessCreate(false)}
				>
					<Alert
						onClose={() => setSuccessCreate(false)}
						severity='success'
					>
						Link created successfully
					</Alert>
				</Snackbar>
				<Snackbar
					open={sucessDelete}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={5000}
					onClose={() => setSuccessDelete(false)}
				>
					<Alert
						onClose={() => setSuccessDelete(false)}
						severity='success'
					>
						Link deleted successfully
					</Alert>
				</Snackbar>
			</CheckAuth>
		);
    } else return ''
}

export default AdvertisingLinks


