import React, {useEffect, useState} from 'react'
import styles from './FreeTrial.module.css'
import Split from '../../components/Split/Split'
import Modal from '../../components/Modal/Modal';
import BlueBtn from '../../components/BlueBtn/BlueBtn';
import OutLineBtn from '../../components/OutLineBtn/OutLineBtn';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuth } from '../../store/slices/userSlice';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { addGb, searchFreeTrial, setDefaultState, setStateLimit, setStatePage } from '../../store/slices/freeTrialSlice';

const FreeTrial = () => {

    const [order ,setOrder] = useState({
        order: 'id',
        order_type: 'ASC'
    })
    const defaultInfoCreate = {
        name: '',
        volume: ''
    }
    const [modalCreate,setModalCreate] = useState(false)
    const [infoCreate,setInfoCreate] = useState(defaultInfoCreate)


    const freeTrialState = useSelector((store)=>store.freeTrialSlice)
    
    console.log(freeTrialState)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [timerInput, setTimerInput] = useState(null);
    
    const [searchText, setSearchText] = useState('');
    const [page,setPage] = useState(1);
    const [limit,setLimit] = useState(10);

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(goSearch, 1500);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [searchText]); 

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changeLimit, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [limit]); 

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changePage, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [page]); 


    useEffect(()=>{
        goSearch()
    },[freeTrialState.page, freeTrialState.limit])


    useEffect(()=>{
        if (freeTrialState.stateLoad == 'error') {
            localStorage.removeItem('token');
            dispatch(setAuth({auth:false,stateAuth:'error'}))
            navigate('/login')
        }
    },[freeTrialState.stateLoad])

    useEffect(()=>{
        goSearch()
    },[order])

    const goSearch = () => {
        if(searchText != "") {
            dispatch(searchFreeTrial({page:freeTrialState.page,limit:freeTrialState.limit,searchText,order}))
        } else {
            dispatch(searchFreeTrial({page:freeTrialState.page,limit:freeTrialState.limit,order}))
        }
    };

    const changeLimit = () => {
        if (!limit) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else if (limit <= 0) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else {
            dispatch(setStateLimit(limit))
        }
    }

    const changePage = () => {
        if (!page) {
            setPage(1)
            dispatch(setStatePage(1))
        } else if (page > freeTrialState.pages) {
            setPage(freeTrialState.pages)
            dispatch(setStatePage(freeTrialState.pages))
        } else {
            dispatch(setStatePage(page))
        }
    }

    useEffect(()=> {
        if (freeTrialState.stateAddGb == 'error') {
            alert("error added")
            setModalCreate(false);
            setInfoCreate(defaultInfoCreate)
            dispatch(setDefaultState())
        } else if (freeTrialState.stateAddGb == 'success') {
            goSearch()
            setModalCreate(false);
            setInfoCreate(defaultInfoCreate)
            dispatch(setDefaultState())
        }
    },[freeTrialState.stateAddGb])

    if (freeTrialState.stateLoad == 'success') {
        return (
            <>
                <div className={styles.users}>
                    <h1>Free Trial</h1>
                    <Split />
                    <div className={styles.tableSection}>
                        <div className={styles.tableControllers}>
                            <BlueBtn text={"Create"} onClick={()=>setModalCreate(true)}/>
                            <input
                                type="text"
                                value={searchText}
                                onChange={(e)=>setSearchText(e.target.value)}
                                placeholder="search"
                            />
                        </div>
                        <div className={styles.table}>
                            <div className={styles.table_row}>
                                <div>ID</div>
                                <div>Full name</div>
                                <div>Mail</div>
                                <div>Proxies type</div>
                                <div>Traffic</div>
                                <div>Location</div>
                                <div>Date</div>
                            </div>
                            {
                                freeTrialState.trials.map((trial)=> (
                                    <div className={styles.table_row}>
                                        <div>{trial.id}</div>
                                        <div>{trial.full_name}</div>
                                        <div>{trial.email}</div>
                                        <div>{trial.type}</div>
                                        <div>{trial.volume} Gb</div>
                                        <div>{trial.country}</div>
                                        <div>{trial.date}</div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={styles.table_pagination}>
                            <div className={styles.table_pagination__rows}>
                                <span>Rows per page</span>
                                <input type="number" value={limit} onChange={(e)=>setLimit(e.target.value)} />
                            </div>
                            <div className={styles.table_pagination__pages}>
                                <KeyboardArrowLeftIcon onClick={()=>setPage((p)=>p == 1 ? 1 : p-1)}/>
                                <input type="number" value={page} onChange={(e)=>setPage(e.target.value)} />
                                <span>of</span>
                                <span>{freeTrialState.pages}</span>
                                <KeyboardArrowRightIcon onClick={()=>setPage((p)=>p == freeTrialState.pages ? p : p+1)}/>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal active={modalCreate} off={() => {setModalCreate(false);setInfoCreate(defaultInfoCreate)}}>
                    <div className={styles.modalEdit} onClick={(e)=>e.stopPropagation()}>
                        <h2>Create</h2>
                        <Split style={{marginTop:"20px", marginBottom:"20px"}}/>
                        <div>
                            <span>Email</span>
                            <input onChange={e=>setInfoCreate({...infoCreate,name:e.target.value})} value={infoCreate.name}></input>
                        </div>
                        <div>
                            <span>Value Mb</span>
                            <input onChange={e=>setInfoCreate({...infoCreate,volume:e.target.value})} value={infoCreate.volume}></input>
                        </div>
                        <div>
                            <span>Proxies type</span>
                            <select>
                                <option value="Residential Proxy">Residential Proxy</option>
                            </select>
                        </div>
                        <Split style={{marginTop:"20px", marginBottom:"20px"}}/>
                        <div className={styles.modalEdit__btns}>
                            <OutLineBtn text={"Cancel"} onClick={()=>{setModalCreate(false);setInfoCreate(defaultInfoCreate)}}/>
                            <BlueBtn text={"Save"} onClick={()=>dispatch(addGb({email:infoCreate.name,volume:(Number(infoCreate.volume)/1024).toFixed(2)}))}/>
                        </div>
                    </div>
                </Modal>
            </>
        )
    } else return ''
}

export default FreeTrial


