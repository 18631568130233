
import { configureStore } from '@reduxjs/toolkit'
import userSlice from './slices/userSlice'
import saleSlice from './slices/saleSlice'
import usersSlice from './slices/usersSlice'
import replenishmentsSlice from './slices/replenishmentsSlice'
import advertisingSlice from './slices/advertisingSlice'
import affiliateSlice from './slices/affiliateSlice'
import proxySlice from './slices/proxySlice'
import apiSlice from './slices/apiSlice'
import blogSlice from './slices/blogSlice'
import freeTrialSlice from './slices/freeTrialSlice'

export const store = configureStore({
  reducer: {userSlice, saleSlice, usersSlice, replenishmentsSlice,advertisingSlice,affiliateSlice,proxySlice, apiSlice, blogSlice, freeTrialSlice},
})