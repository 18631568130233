import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	links: [],
	count: 0,
	page: 1,
	limit: 10,
	pages: 1,
	stateLoad: "idle",
	stateUpdate: "idle",
	stateCreate: "idle",
	stateArchive: "idle",
};

export const loadPromocodes = createAsyncThunk(
	"promocode/loadPromocodes",
	async ({ page, limit, type }) => {
		if (localStorage.getItem("token")) {
			const resp = await axios.get(
				"https://api.admin.gsocks.net/promocode/",
				{
					headers: {
						authorization: `session ${localStorage.getItem(
							"token"
						)}`,
					},
					params: {
						page,
						limit,
						is_deleted: type == "archive",
					},
				}
			);
			console.log("REQUEST");
			return resp.data;
		}
	}
);

export const toArchive = createAsyncThunk("promocode/toArchive", async (id) => {
	if (localStorage.getItem("token")) {
		const resp = await axios.post(
			`https://api.admin.gsocks.net/promocode/delete/${id}`,
			{},
			{
				headers: {
					authorization: `session ${localStorage.getItem("token")}`,
				},
			}
		);
		return resp.data;
	}
});

export const changeStatus = createAsyncThunk(
	"promocode/changeStatus",
	async (id) => {
		if (localStorage.getItem("token")) {
			const resp = await axios.put(
				`https://api.admin.gsocks.net/promocode/status/${id}`,
				{},
				{
					headers: {
						authorization: `session ${localStorage.getItem(
							"token"
						)}`,
					},
				}
			);
			return resp.data;
		}
	}
);

export const searchPromocode = createAsyncThunk(
	"promocode/searchPromocode",
	async ({ page, limit, searchText }) => {
		if (localStorage.getItem("token")) {
			const resp = await axios.get(
				"https://api.admin.gsocks.net/promocode/",
				{
					headers: {
						authorization: `session ${localStorage.getItem(
							"token"
						)}`,
					},
					params: {
						page,
						limit,
						filter: searchText,
					},
				}
			);
			return resp.data;
		}
	}
);

export const createPromocodeAction = createAsyncThunk(
	"promocode/createPromocodeAction",
	async ({ code, type, discount, use }) => {
		if (localStorage.getItem("token")) {
			const resp = await axios.post(
				"https://api.admin.gsocks.net/promocode/",
				{
					name: code,
					description: code,
					code,
					discount: Number(discount),
					proxy_type_limit: type,
					usage_limit: Number(use),
				},
				{
					headers: {
						authorization: `session ${localStorage.getItem(
							"token"
						)}`,
					},
				}
			);
			return resp.data;
		}
	}
);

export const promocodeSlice = createSlice({
	name: "promocode",
	initialState,
	reducers: {
		setStateUpdate: (state, action) => {
			state.stateUpdate = action.payload;
		},
		setStateCreate: (state, action) => {
			state.stateCreate = action.payload;
		},
		setStateLimit: (state, action) => {
			state.limit = Number(action.payload);
		},
		setStatePage: (state, action) => {
			state.page = Number(action.payload);
		},
		setStateArchive: (state, action) => {
			state.stateArchive = action.payload;
		},
	},
	extraReducers: (build) => {
		build.addCase(loadPromocodes.fulfilled, (state, action) => {
			if (action.payload) {
				state.count = action.payload.count;
				state.links = action.payload.links;
				state.pages = Math.ceil(state.count / state.limit);
				state.stateLoad = "success";
			}
		});
		build.addCase(loadPromocodes.rejected, (state, action) => {
			state.stateLoad = "error";
		});
		build.addCase(createPromocodeAction.fulfilled, (state, action) => {
			state.stateCreate = "success";
		});
		build.addCase(createPromocodeAction.rejected, (state, action) => {
			state.stateCreate = "error";
		});
		build.addCase(searchPromocode.fulfilled, (state, action) => {
			state.count = action.payload.count;
			state.links = action.payload.links;
			state.pages = Math.ceil(state.count / state.limit);
			state.stateLoad = "success";
		});
		build.addCase(searchPromocode.rejected, (state, action) => {
			state.stateLoad = "error";
		});
		build.addCase(changeStatus.fulfilled, (state, action) => {
			state.stateUpdate = "success";
		});
		build.addCase(changeStatus.rejected, (state, action) => {
			state.stateUpdate = "error";
		});
		build.addCase(toArchive.fulfilled, (state, action) => {
			state.stateArchive = "success";
		});
		build.addCase(toArchive.rejected, (state, action) => {
			state.stateArchive = "error";
		});
	},
});

export const {
	setStateUpdate,
	setStateLimit,
	setStatePage,
	setStateCreate,
	setStateArchive,
} = promocodeSlice.actions;
export default promocodeSlice.reducer;
