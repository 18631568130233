// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recharts-tooltip-cursor {
  display: none;
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.9); /* Светлый фон для самого тултипа */
  border: 1px solid #d3d3d3;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}`, "",{"version":3,"sources":["webpack://./src/components/BarChartComponent/charStyle.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,0CAA0C,EAAE,mCAAmC;EAC/E,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,0CAA0C;AAC5C","sourcesContent":[".recharts-tooltip-cursor {\n  display: none;\n}\n\n.custom-tooltip {\n  background-color: rgba(255, 255, 255, 0.9); /* Светлый фон для самого тултипа */\n  border: 1px solid #d3d3d3;\n  padding: 10px;\n  border-radius: 5px;\n  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
