import React from "react";
import styles from "./BarLeft.module.css";
import { useNavigate } from "react-router-dom";
import Logo from "../../img/logo2.png";

const BarLeft = () => {
	const navigator = useNavigate();

	return (
		<div className={styles.barleft}>
			<img src={Logo} />
			<div className={styles.links}>
				<a onClick={() => navigator("/")}>Dashboard</a>
				<a onClick={() => navigator("/statistic")}>Statistic</a>
				{/* <a onClick={()=> navigator('/proxies')}>Proxies</a> */}
				<a onClick={() => navigator("/proxies_residential")}>
					Proxies Residential
				</a>
				<a onClick={() => navigator("/proxies_mobile")}>
					Proxies Mobile
				</a>
				<a onClick={() => navigator("/proxies_datacenter")}>
					Proxies Datacenter
				</a>
				<a onClick={() => navigator("/proxies_private")}>
					Proxies Private
				</a>
				<a onClick={() => navigator("/api_proxies")}>API Proxies</a>
				<a onClick={() => navigator("/users")}>Users</a>
				<a onClick={() => navigator("/sales")}>Sales</a>
				<a onClick={() => navigator("/advertising_links")}>Links</a>
				<a onClick={() => navigator("/affiliate_program")}>Affiliate</a>
				<a onClick={() => navigator("/replenishments")}>
					Replenishments
				</a>
				<a onClick={() => navigator("/api")}>API</a>
				<a onClick={() => navigator("/blog")}>Blog</a>
				<a onClick={() => navigator("/free_trial")}>Free Trial</a>
				<a onClick={() => navigator("/promocode")}>Promocode</a>
			</div>
		</div>
	);
};

export default BarLeft;
