import React, { useRef, useEffect, useState } from "react";
import Calendar from "react-calendar"; // Убедитесь, что это правильный импорт
import "react-calendar/dist/Calendar.css";
import styles from "./datarange.module.css";
import CalendarIcon from "@mui/icons-material/EditCalendar";

const DateRange = ({ dateRange, setDateRange }) => {
    const [startDate, endDate] = dateRange;
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const calendarRef = useRef(null);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleDateChange = (value) => {
        if (Array.isArray(value) && value.length === 2) {
            const [newStartDate, newEndDate] = value;
            if (newStartDate && newEndDate) {
                setDateRange([newStartDate, newEndDate]);  
            }
        } else if (value instanceof Date) {
            setDateRange([value, value]);
        }
        setIsCalendarOpen(false);
    };

    const handleClickOutside = (event) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
            setIsCalendarOpen(false);
        }
    };

    useEffect(() => {
        if (isCalendarOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isCalendarOpen]);

    const disableDates = (date) => {
        const today = new Date();
        return date > today;
    };

    const formattedDate =
        startDate && endDate && startDate.getTime() === endDate.getTime()
            ? formatDate(startDate)
            : `${formatDate(startDate)} - ${formatDate(endDate)}`;

    return (
        <div className={styles.date_picker_container} ref={calendarRef}>
            <div
                className={styles.date_input}
                onClick={() => setIsCalendarOpen(!isCalendarOpen)}
            >
                {formattedDate}
                <CalendarIcon />
            </div>
            {isCalendarOpen && (
                <div className={styles.calendar_container}>
                    <Calendar
                        selectRange
                        value={dateRange}
                        onChange={handleDateChange}
                        locale="en-US" // Убедитесь, что этот код поддерживается
                        className={styles.calendar}
                        tileDisabled={({ date }) => disableDates(date)}
                    />
                </div>
            )}
        </div>
    );
};

export default DateRange;
