import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthAction } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";

const CheckAuth = ({ children }) => {
	const user = useSelector((store) => store.userSlice);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (user.stateAuth == "idle" && localStorage.getItem("token")) {
			dispatch(checkAuthAction(localStorage.getItem("token")));
		} else if (user.stateAuth == "idle" && !localStorage.getItem("token")) {
			navigate("/login");
		} else if (user.stateAuth == "error") {
			navigate("/login");
		}
	}, [user]);

	if (user.stateAuth == "success") {
		return children;
	} else {
		return <h1>Loading</h1>;
	}
};

export default CheckAuth;
