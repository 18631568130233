import React, {useEffect, useState} from 'react'
import Split from '../../components/Split/Split'
import styles from './statistic.module.css'
import BarChartComponent from '../../components/BarChartComponent/BarChartComponent'
import DateRange from '../../components/DateRange/DateRange'
import axios from 'axios'
import { setAuth } from '../../store/slices/userSlice';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
};

const weeksAgo = () => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
};

const Statistic = () => {
    const [dateUsers, setDateUsers] = useState([weeksAgo(), new Date()])
    const [dateTraffic, setDateTraffic] = useState([weeksAgo(), new Date()])
    const [rangeDateUsers, setRangeDateUsers] = useState(1)
    const [dataUsers, setDataUsers] = useState([])

    const [rangeDateTraffic, setRangeDateTraffic] = useState(1)
    const [dataTraffic, setDataTraffic] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(()=>{
        const initData = async () => {
            console.log(dateTraffic, "DATEUSER")
            if (localStorage.getItem('token')) {
                try {     
                    const resp = await axios.get(
                        `https://api.admin.gsocks.net/analytic/registrations?start_date=${formatDate(dateUsers[0])}&end_date=${formatDate(dateUsers[1])}`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                'Authorization': `session ${localStorage.getItem('token')}`
                            }
                        }
                    )
                    const tmp = []
                    if (rangeDateUsers == '1') {
                        for (const key in resp.data.day1) {
                            tmp.push({date:key,verifiedReg: resp.data.day1[key][0], unverifiedReg: resp.data.day1[key][1], totalReg:resp.data.day1[key][2]})
                        }
                    } else if (rangeDateUsers == '7') { 
                        for (const key in resp.data.day7) {
                            tmp.push({date:key,verifiedReg: resp.data.day7[key][0], unverifiedReg: resp.data.day7[key][1], totalReg:resp.data.day7[key][2]})
                        }
                    } else { 
                        for (const key in resp.data.day30) {
                            tmp.push({date:key,verifiedReg: resp.data.day30[key][0], unverifiedReg: resp.data.day30[key][1], totalReg:resp.data.day30[key][2]})
                        }
                    }
                    setDataUsers(tmp)
                } catch {
                    localStorage.removeItem('token');
                    dispatch(setAuth({auth:false,stateAuth:'error'}))
                    navigate('/login')
                }
            }
        }

        initData()
    },[dateUsers,rangeDateUsers])


    useEffect(()=>{
        console.log(1)
        const init = async () => {
            console.log(dateTraffic, "DATETRAFIC")
            try {
                const resp = await axios.get(
                    `https://api.admin.gsocks.net/analytic/residential-traffic-expenses?start_date=${formatDate(dateTraffic[0])}&end_date=${formatDate(dateTraffic[1])}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `session ${localStorage.getItem('token')}`
                        }
                    }
                )
                const tmp = []
                if (rangeDateTraffic == '1') {
                    for (const key in resp.data.day1) {
                        tmp.push({date:key,value: resp.data.day1[key][0]})
                    }
                } else if (rangeDateTraffic == '7') { 
                    for (const key in resp.data.day7) {
                        tmp.push({date:key,value: resp.data.day7[key][0]})
                    }
                } else { 
                    for (const key in resp.data.day30) {
                        tmp.push({date:key,value: resp.data.day30[key][0]})
                    }
                }
                setDataTraffic(tmp)
            } catch {
                localStorage.removeItem('token');
                dispatch(setAuth({auth:false,stateAuth:'error'}))
                navigate('/login')
            }
        }

        init();
    },[dateTraffic,rangeDateTraffic])

    console.log(dateTraffic, "CHANGE")

    return (
        <>
            <div className={styles.statistic}>
                <h1>Statistic</h1>
                <Split />
                <h2>Users</h2>
                <div className={styles.statistic__controllers}>
                    <DateRange 
                        dateRange={dateUsers}
                        setDateRange={(range)=>setDateUsers(range)}
                    />
                    <select value={rangeDateUsers} onChange={(e)=>setRangeDateUsers(e.target.value)}>
                        <option value="1">1 days</option>
                        <option value="7">7 days</option>
                        <option value="30">30 days</option>
                    </select>
                </div>
                <BarChartComponent data={dataUsers} type={"Users"}/>
                <h2>Traffic consumption</h2>
                <div className={styles.statistic__controllers}>
                    <DateRange 
                        dateRange={dateTraffic}
                        setDateRange={(range)=>setDateTraffic(range)}
                    />
                    <select value={rangeDateTraffic} onChange={(e)=>setRangeDateTraffic(e.target.value)}>
                        <option value="1">1 days</option>
                        <option value="7">7 days</option>
                        <option value="30">30 days</option>
                    </select>
                </div>
                <BarChartComponent data={dataTraffic} type={"Traffic consumption"}/>
            </div>
        </>
    )
}

export default Statistic


