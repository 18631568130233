import React, { useEffect, useState } from "react";
import styles from "./Affiliate.module.css";
import Split from "../../components/Split/Split";
import CreateIcon from "@mui/icons-material/Create";
import Modal from "../../components/Modal/Modal";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import BlueBtn from "../../components/BlueBtn/BlueBtn";
import OutLineBtn from "../../components/OutLineBtn/OutLineBtn";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuth } from "../../store/slices/userSlice";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
	cancelAffiliate,
	deleteAffiliate,
	loadAffiliate,
	searchAffiliate,
	setStateLimit,
	setStatePage,
	setStateUpdate,
	updateAffiliate,
} from "../../store/slices/affiliateSlice";
import CheckAuth from "../../components/CheckAuth";

const Affiliate = () => {
	const [order, setOrder] = useState({
		order: "id",
		order_type: "ASC",
	});

	const AffiliateState = useSelector((store) => store.affiliateSlice);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [activeModalEdit, setActiveModalEdit] = useState(false);
	const [activeModalEditSave, setActiveModalEditSave] = useState(false);
	const [activeModalEditSaveCancel, setActiveModalEditSaveCancel] =
		useState(false);

	const [alertEditSuccess, setAlertEditSuccess] = useState(false);
	const [alertCancelTextError, setAlertCancelTextError] = useState(false);

	const [infoDelete, setInfoDelete] = useState("");

	const [seleteced, setSelected] = useState({
		id: -1,
		status: "",
		note: "",
		balance: "",
		name: "",
		description: "",
		statuses: [],
	});

	const [saveFirst, setSaveFirst] = useState(false);

	const [timerInput, setTimerInput] = useState(null);

	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	useEffect(() => {
		if (timerInput) {
			clearTimeout(timerInput);
		}

		const newTimeoutId = setTimeout(goSearch, 1500);
		setTimerInput(newTimeoutId);

		return () => {
			clearTimeout(newTimeoutId);
		};
	}, [searchText]);

	useEffect(() => {
		if (timerInput) {
			clearTimeout(timerInput);
		}

		const newTimeoutId = setTimeout(changeLimit, 1000);
		setTimerInput(newTimeoutId);

		return () => {
			clearTimeout(newTimeoutId);
		};
	}, [limit]);

	useEffect(() => {
		if (timerInput) {
			clearTimeout(timerInput);
		}

		const newTimeoutId = setTimeout(changePage, 1000);
		setTimerInput(newTimeoutId);

		return () => {
			clearTimeout(newTimeoutId);
		};
	}, [page]);

	const [modalChangeBalance, setModalChangeBalance] = useState(false);
	const [infoBalance, setInfoBalance] = useState("");
	const [alertBalance, setAlertBalance] = useState(false);

	useEffect(() => {
		if (saveFirst) {
			const defLink = AffiliateState.affiliates.find(
				(s) => s.id === seleteced.id
			);

			if (defLink.status != seleteced.status) {
				setActiveModalEditSaveCancel(true);
			} else if (defLink.balance != seleteced.balance) {
				setModalChangeBalance(true);
			} else {
				setActiveModalEditSave(true);
			}

			setSaveFirst(false);
		}
	}, [saveFirst]);

	useEffect(() => {
		if (AffiliateState.stateUpdate === "success") {
			dispatch(
				loadAffiliate({
					page: AffiliateState.page,
					limit: AffiliateState.limit,
					order,
				})
			);
			setAlertEditSuccess(true);
			dispatch(setStateUpdate("idle"));
		}
	}, [AffiliateState.stateUpdate]);

	useEffect(() => {
		goSearch();
	}, [AffiliateState.page, AffiliateState.limit]);

	useEffect(() => {
		if (AffiliateState.stateLoad == "error") {
			localStorage.removeItem("token");
			dispatch(setAuth({ auth: false, stateAuth: "error" }));
			navigate("/login");
		}
	}, [AffiliateState.stateLoad]);

	useEffect(() => {
		goSearch();
	}, [order]);

	const goSearch = () => {
		if (searchText != "") {
			dispatch(
				searchAffiliate({
					page: AffiliateState.page,
					limit: AffiliateState.limit,
					searchText,
					order,
				})
			);
		} else {
			dispatch(
				loadAffiliate({
					page: AffiliateState.page,
					limit: AffiliateState.limit,
					order,
				})
			);
		}
	};

	const changeLimit = () => {
		if (!limit) {
			setLimit(1);
			dispatch(setStateLimit(1));
		} else if (limit <= 0) {
			setLimit(1);
			dispatch(setStateLimit(1));
		} else {
			dispatch(setStateLimit(limit));
		}
	};

	const changePage = () => {
		if (!page) {
			setPage(1);
			dispatch(setStatePage(1));
		} else if (page > AffiliateState.pages) {
			setPage(AffiliateState.pages);
			dispatch(setStatePage(AffiliateState.pages));
		} else {
			dispatch(setStatePage(page));
		}
	};

	const [fieldChange, setFieldChange] = useState({
		status: false,
		balance: false,
	});

	const saveCancel = () => {
		if (infoDelete != "") {
			setActiveModalEditSaveCancel(false);
			setFieldChange({ ...fieldChange, status: true });
			const defLink = AffiliateState.affiliates.find(
				(s) => s.id === seleteced.id
			);

			if (defLink.balance != seleteced.balance) {
				setModalChangeBalance(true);
			} else {
				setActiveModalEditSave(true);
			}
		} else {
			setAlertCancelTextError(true);
		}
	};

	const saveBalance = () => {
		if (infoBalance != "") {
			setModalChangeBalance(false);
			setActiveModalEditSave(true);
			setFieldChange({ ...fieldChange, balance: true });
		} else {
			setAlertBalance(true);
		}
	};

	const save = () => {
		setInfoDelete("");
		setInfoBalance("");
		const defLink = AffiliateState.affiliates.find(
			(s) => s.id === seleteced.id
		);

		dispatch(
			updateAffiliate({
				name: seleteced.name,
				description: infoBalance,
				status: infoBalance != "" ? seleteced.status : defLink.status,
				balance:
					infoBalance != ""
						? Number(seleteced.balance)
						: defLink.balance,
				note: seleteced.note,
				id: seleteced.id,
			})
		);

		setActiveModalEditSave(false);
	};

	const clickSort = (type) => {
		if (order.order == type) {
			setOrder({
				...order,
				order_type: order.order_type == "ASC" ? "DESC" : "ASC",
			});
		} else {
			setOrder({ order: type, order_type: "ASC" });
		}
	};

	const [successDelete, setSuccessDelete] = useState(false);

	const deleteAffiliateAction = (id) => {
		dispatch(deleteAffiliate(id));
		setActiveModalEdit(false);
		setSuccessDelete(true);
		goSearch();
	};

	if (AffiliateState.stateLoad == "success") {
		return (
			<CheckAuth>
				<div className={styles.users}>
					<h1>Affiliate</h1>
					<Split />
					<div className={styles.tableSection}>
						<div className={styles.tableControllers}>
							<input
								type='text'
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
								placeholder='search'
							/>
						</div>
						<div className={styles.table}>
							<div className={styles.table_row}>
								<div>ID</div>
								<div>Full name</div>
								<div>Mail</div>
								<div>Date</div>
								<div>Users</div>
								<div>Link</div>
								<div>Affiliate Balance</div>
								<div>Status</div>
								<div>Actions</div>
							</div>
							{AffiliateState.affiliates.map((af) => (
								<div className={styles.table_row}>
									<div>{af.id}</div>
									<div>{af.name}</div>
									<div>{af.email}</div>
									<div>{af.create}</div>
									<div>{af.count}</div>
									<div>{af.code}</div>
									<div>{af.balance}</div>
									<div>{af.status}</div>
									<div
										onClick={() => {
											setActiveModalEdit(true);
											setSelected({
												...af,
												statuses:
													af.status === "Inactive"
														? ["Inactive", "Active"]
														: [
																"Active",
																"Inactive",
														  ],
											});
										}}
									>
										<CreateIcon />
									</div>
								</div>
							))}
						</div>
						<div className={styles.table_pagination}>
							<div className={styles.table_pagination__rows}>
								<span>Rows per page</span>
								<input
									type='number'
									value={limit}
									onChange={(e) => setLimit(e.target.value)}
								/>
							</div>
							<div className={styles.table_pagination__pages}>
								<KeyboardArrowLeftIcon
									onClick={() =>
										setPage((p) => (p == 1 ? 1 : p - 1))
									}
								/>
								<input
									type='number'
									value={page}
									onChange={(e) => setPage(e.target.value)}
								/>
								<span>of</span>
								<span>{AffiliateState.pages}</span>
								<KeyboardArrowRightIcon
									onClick={() =>
										setPage((p) =>
											p == AffiliateState.pages
												? p
												: p + 1
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<Modal
					active={activeModalEdit}
					off={() => setActiveModalEdit(false)}
				>
					<div
						className={styles.modalEdit}
						onClick={(e) => e.stopPropagation()}
					>
						<h2>Action</h2>
						<Split
							style={{ marginTop: "20px", marginBottom: "20px" }}
						/>
						<div>
							<span>Status</span>
							<select
								onChange={(e) => {
									setSelected({
										...seleteced,
										status: e.target.value,
									});
								}}
								value={seleteced.status}
							>
								{seleteced.statuses.map((s) => (
									<option value={s}>{s}</option>
								))}
							</select>
						</div>
						<div>
							<span>Balance</span>
							<input
								type='number'
								onChange={(e) => {
									setSelected({
										...seleteced,
										balance: e.target.value,
									});
								}}
								value={seleteced.balance}
							></input>
						</div>
						<div>
							<span>Full name</span>
							<input
								onChange={(e) => {
									setSelected({
										...seleteced,
										name: e.target.value,
									});
								}}
								value={seleteced.name}
							></input>
						</div>
						<div>
							<span>Description</span>
							<textarea
								onChange={(e) => {
									setSelected({
										...seleteced,
										description: e.target.value,
									});
								}}
								value={seleteced.description}
							></textarea>
						</div>
						<div>
							<span>Note</span>
							<textarea
								onChange={(e) =>
									setSelected({
										...seleteced,
										note: e.target.value,
									})
								}
								value={seleteced.note ? seleteced.note : ""}
							></textarea>
						</div>
						<Split
							style={{ marginTop: "20px", marginBottom: "20px" }}
						/>
						<div className={styles.modalEdit__btns_v2}>
							<BlueBtn
								text={"Delete"}
								onClick={() =>
									deleteAffiliateAction(seleteced.id)
								}
								style={{
									backgroundColor: "red",
									border: "2px solid red",
								}}
							/>
							<div>
								<OutLineBtn
									text={"Cancel"}
									onClick={() => setActiveModalEdit(false)}
								/>
								<BlueBtn
									text={"Save"}
									onClick={() => {
										setActiveModalEdit(false);
										setSaveFirst(true);
									}}
								/>
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					active={activeModalEditSave}
					off={() => setActiveModalEditSave(false)}
				>
					<div
						className={styles.modalEdit}
						onClick={(e) => e.stopPropagation()}
					>
						<h2 style={{ marginBottom: "30px", marginTop: "20px" }}>
							Are you sure to save the changes ?
						</h2>
						<div className={styles.modalEdit__btns}>
							<OutLineBtn
								text={"Cancel"}
								onClick={() => setActiveModalEditSave(false)}
							/>
							<BlueBtn text={"Save"} onClick={save} />
						</div>
					</div>
				</Modal>
				<Modal
					active={activeModalEditSaveCancel}
					off={() => setActiveModalEditSaveCancel(false)}
				>
					<div
						className={styles.modalEdit}
						onClick={(e) => e.stopPropagation()}
					>
						<h2 style={{ marginBottom: "30px", marginTop: "20px" }}>
							Enter the reason for the status change
						</h2>
						<div className={styles.modalEdit__enter}>
							<span>Note</span>
							<textarea
								value={infoDelete}
								onChange={(e) => setInfoDelete(e.target.value)}
							></textarea>
						</div>
						<div className={styles.modalEdit__btns}>
							<OutLineBtn
								text={"Cancel"}
								onClick={() =>
									setActiveModalEditSaveCancel(false)
								}
							/>
							<BlueBtn text={"Save"} onClick={saveCancel} />
						</div>
					</div>
				</Modal>
				<Modal
					active={modalChangeBalance}
					off={() => {
						setModalChangeBalance(false);
					}}
				>
					<div
						className={styles.modalEdit}
						onClick={(e) => e.stopPropagation()}
					>
						<h2 style={{ marginBottom: "30px", marginTop: "20px" }}>
							Enter the reason for the balance change
						</h2>
						<div className={styles.modalEdit__enter}>
							<span>Note</span>
							<textarea
								value={infoBalance}
								onChange={(e) => setInfoBalance(e.target.value)}
							></textarea>
						</div>
						<div className={styles.modalEdit__btns}>
							<OutLineBtn
								text={"Cancel"}
								onClick={() => setModalChangeBalance(false)}
							/>
							<BlueBtn text={"Save"} onClick={saveBalance} />
						</div>
					</div>
				</Modal>
				<Snackbar
					open={alertEditSuccess}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={5000}
					onClose={() => setAlertEditSuccess(false)}
				>
					<Alert
						onClose={() => setAlertEditSuccess(false)}
						severity='success'
					>
						Data updated successfully !
					</Alert>
				</Snackbar>
				<Snackbar
					open={alertCancelTextError}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={5000}
					onClose={() => setAlertCancelTextError(false)}
				>
					<Alert
						onClose={() => setAlertCancelTextError(false)}
						severity='error'
					>
						Enter the reason for the status change !
					</Alert>
				</Snackbar>
				<Snackbar
					open={alertBalance}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={5000}
					onClose={() => setAlertBalance(false)}
				>
					<Alert
						onClose={() => setAlertBalance(false)}
						severity='error'
					>
						Enter the reason for the balance change !
					</Alert>
				</Snackbar>
				<Snackbar
					open={successDelete}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={5000}
					onClose={() => setSuccessDelete(false)}
				>
					<Alert
						onClose={() => setSuccessDelete(false)}
						severity='success'
					>
						Affiliate deleted successfully
					</Alert>
				</Snackbar>
			</CheckAuth>
		);
	} else return "";
};

export default Affiliate;
