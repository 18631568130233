import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import CheckAuth from "../../components/CheckAuth";

const ProxiesInfo = () => {
	const { id, type } = useParams();

	const [show, setShow] = useState(true);
	const [content, setContent] = useState("");

	useEffect(() => {
		if (id) {
			if (type == "residential") {
				axios
					.get(
						`https://api.admin.gsocks.net/proxy/residential-proxy-list/${id}`,
						{
							headers: {
								authorization: `session ${localStorage.getItem(
									"token"
								)}`,
								"Content-Type": "application/json",
							},
						}
					)
					.then((resp) => {
						if (resp.status == 200) {
							setShow(true);
							setContent(resp.data);
						} else {
							window.open("https://gsocks.net/user/login");
						}
					});
			} else {
				axios
					.get(
						`https://api.admin.gsocks.net/proxy/mobile-proxy-list/${id}`,
						{
							headers: {
								authorization: `session ${localStorage.getItem(
									"token"
								)}`,
								"Content-Type": "application/json",
							},
						}
					)
					.then((resp) => {
						if (resp.status == 200) {
							setShow(true);
							setContent(resp.data);
						} else {
							window.open("https://gsocks.net/user/login");
						}
					});
			}
		}
	}, [id]);

	return (
		<CheckAuth>
			{show ? (
				<div dangerouslySetInnerHTML={{ __html: content }} />
			) : (
				<h1>Not auth</h1>
			)}
		</CheckAuth>
	);
};

export default ProxiesInfo;
