import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    replenishments:[],
    count: 0,
    page: 1,
    limit: 10,
    pages: 1,
    stateLoad: 'idle',
    stateUpdate: 'idle',
}

export const loadReplenishments = createAsyncThunk('replenishments/loadReplenishments', async ({page,limit, status})=>{
    const resp = await axios.get('https://api.admin.gsocks.net/finance',{
        headers: {
          'authorization': `session ${localStorage.getItem('token')}`
        },
        params: {
          page,limit, 
        status: status == "All" ? undefined : status

        }
    })
    return resp.data
})

export const cancelReplenishments = createAsyncThunk('replenishments/cancelReplenishments', async (data)=>{
    const resp = await axios.post(`https://api.admin.gsocks.net/finance/cancel/${data.id}`,{note:data.note ? data.note : ""},{
        headers: {
          'authorization': `session ${localStorage.getItem('token')}`
        }
    })
    return resp.data
})

export const updateReplenishments = createAsyncThunk('replenishments/updateReplenishments', async (data)=>{
  const resp = await axios.post(`https://api.admin.gsocks.net/finance/update-note/${data.id}`,{note:data.note ? data.note : ""},{
    headers: {
      'authorization': `session ${localStorage.getItem('token')}`
    }
  })
  return resp.data
})

export const searchReplenishments = createAsyncThunk('replenishments/searchReplenishments', async ({page,limit,searchText,status})=>{
  const resp = await axios.get('https://api.admin.gsocks.net/finance',{
      headers: {
        'authorization': `session ${localStorage.getItem('token')}`
      },
      params: {
        page,limit,
        'filter': searchText,
        status: status == "All" ? undefined : status
      }
  })
  return resp.data
})

export const replenishmentsSlice = createSlice({
  name: 'replenishments',
  initialState,
  reducers: {
    setStateUpdate: (state,action) => {
        state.stateUpdate = action.payload
    },
    setStateLimit: (state,action) => {
      state.limit = Number(action.payload)
    },
    setStatePage: (state,action) => {
      state.page = Number(action.payload)
    }
  },
  extraReducers: (build) => {
    build.addCase(loadReplenishments.fulfilled, (state, action) => {
      state.count = action.payload.count
      state.replenishments = action.payload.payments
      state.pages = Math.ceil(state.count / state.limit)
      state.stateLoad = 'success'
    })
    build.addCase(loadReplenishments.rejected, (state, action) => {
      state.stateLoad = 'error'
    })
    build.addCase(searchReplenishments.fulfilled, (state, action) => {
      state.count = action.payload.count
      state.replenishments = action.payload.payments
      state.stateLoad = 'success'
    })
    build.addCase(cancelReplenishments.fulfilled, (state, action) => {
      state.stateUpdate = 'success'
    })
    build.addCase(updateReplenishments.fulfilled, (state, action) => {
      state.stateUpdate = 'success'
    })
  }
})


export const {setStateUpdate, setStateLimit, setStatePage} = replenishmentsSlice.actions
export default replenishmentsSlice.reducer