import React, {useEffect, useState} from 'react'
import styles from './blog.module.css'
import Split from '../../components/Split/Split'
import CreateIcon from '@mui/icons-material/Create';
import BlueBtn from '../../components/BlueBtn/BlueBtn';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { loadPosts, setStatePage, setStateLimit, updateStatus, deletePost } from '../../store/slices/blogSlice';
import { useNavigate } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import Modal from '../../components/Modal/Modal'
import OutLineBtn from '../../components/OutLineBtn/OutLineBtn';
import { setAuth } from '../../store/slices/userSlice';

const Blog = () => {

    const navigate = useNavigate()

    const blogState = useSelector((store)=>store.blogSlice)
    const dispatch = useDispatch()

    const [page,setPage] = useState(1);
    const [limit,setLimit] = useState(10);

    const [idForDel, setIdForDel] = useState(-1)
    const [modal,setModal] = useState(false)

    const [timerInput, setTimerInput] = useState(null);

    useEffect(()=>{
        dispatch(loadPosts({page:blogState.page, limit:blogState.limit}))
    },[blogState.page, blogState.limit])

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changeLimit, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [limit]); 

    useEffect(() => {
        if (timerInput) {
          clearTimeout(timerInput);
        }
    
        const newTimeoutId = setTimeout(changePage, 1000);
        setTimerInput(newTimeoutId);
    
        return () => {
          clearTimeout(newTimeoutId);
        };
    }, [page]);

    const changeLimit = () => {
        if (!limit) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else if (limit <= 0) {
            setLimit(1)
            dispatch(setStateLimit(1))
        } else {
            dispatch(setStateLimit(limit))
        }
    }

    const changePage = () => {
        if (!page) {
            setPage(1)
            dispatch(setStatePage(1))
        } else if (page > blogState.pages) {
            setPage(blogState.pages)
            dispatch(setStatePage(blogState.pages))
        } else {
            dispatch(setStatePage(page))
        }
    }

    const chageVisibility = async (type, post) => {
        const p = {...post, status:type}
        delete p['show_date']
        if (localStorage.getItem('token')) {
            try {
                await axios.patch(`https://api.admin.gsocks.net/blog/${post.id}`, p, {
                    headers: {
                        'authorization': `session ${localStorage.getItem('token')}`,
                    }
                })
            } catch {
                localStorage.removeItem('token');
                dispatch(setAuth({auth:false,stateAuth:'error'}))
                navigate('/login')
            }
        }
        dispatch(updateStatus({post,type}))
    }

    const delPost = async () => {
        if (localStorage.getItem('token')) {
            try {
                await axios.delete(`https://api.admin.gsocks.net/blog/${idForDel}`, {
                    headers: {
                        'authorization': `session ${localStorage.getItem('token')}`,
                    }
                })
                dispatch(deletePost(idForDel))
                setModal(false)
            } catch {
                localStorage.removeItem('token');
                dispatch(setAuth({auth:false,stateAuth:'error'}))
                navigate('/login')
            }
        }
    }

    if (blogState.stateLoad == 'success') {
        return (
            <>
                <div className={styles.users}>
                    <h1>Blog</h1>
                    <Split />
                    <div className={styles.tableSection}>
                        <div className={styles.tableControllers}>
                            <BlueBtn text={"Create"} onClick={()=>navigate('/blog/create')}/>
                        </div>
                        <div className={styles.table}>
                            <div className={styles.table_row}>
                                <div>ID</div>
                                <div>Name</div>
                                <div>Date</div>
                                <div>Actions</div>
                            </div>
                            {
                                blogState.posts.map((post)=> (
                                    <div className={styles.table_row} key={post.id}>
                                        <div>{post.id}</div>
                                        <div>{post.title}</div>
                                        <div>{post.create}</div>
                                        <div><CreateIcon onClick={()=>navigate(`update/${post.id}`)}/>{post.status == "Show" ? <VisibilityIcon onClick={()=>chageVisibility("Hide",post)} /> : <VisibilityOffIcon onClick={()=>chageVisibility("Show",post)}/>} <DeleteIcon onClick={()=>{setIdForDel(post.id);setModal(true)}}/></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={styles.table_pagination}>
                            <div className={styles.table_pagination__rows}>
                                <span>Rows per page</span>
                                <input type="number" value={limit} onChange={(e)=>setLimit(e.target.value)} />
                            </div>
                            <div className={styles.table_pagination__pages}>
                                <KeyboardArrowLeftIcon onClick={()=>setPage((p)=>p == 1 ? 1 : p-1)}/>
                                <input type="number" value={page} onChange={(e)=>setPage(e.target.value)} />
                                <span>of</span>
                                <span>{blogState.pages}</span>
                                <KeyboardArrowRightIcon onClick={()=>setPage((p)=>p == blogState.pages ? p : p+1)}/>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal active={modal} off={()=>setModal(false)}>
                <   div className={styles.modalDell} onClick={(e)=>e.stopPropagation()}>
                        <h2 style={{marginBottom:"30px", marginTop:"20px"}}>Are you sure to delete ?</h2>
                        <div className={styles.modalDell__btns}>
                            <OutLineBtn text={"Cancel"} onClick={()=>setModal(false)}/>
                            <BlueBtn text={"Delete"} onClick={() => delPost()}/>
                        </div>
                    </div>
                </Modal>
            </>
        )
    } else return ''
}

export default Blog


