import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	trials: [],
	count: 0,
	page: 1,
	limit: 10,
	pages: 1,
	stateLoad: "idle",
	stateAddGb: "idle",
	type: "",
};

export const addGb = createAsyncThunk(
	"freeTrual/addGb",
	async ({ email, volume, type }) => {
		if (type == "residential") {
			const resp = await axios.post(
				"https://api.admin.gsocks.net/proxy/add-residential-traffic",
				{ email, volume },
				{
					headers: {
						authorization: `session ${localStorage.getItem(
							"token"
						)}`,
					},
				}
			);
			return resp.data;
		} else {
			const resp = await axios.post(
				"https://api.admin.gsocks.net/proxy/add-mobile-traffic",
				{ email, volume },
				{
					headers: {
						authorization: `session ${localStorage.getItem(
							"token"
						)}`,
					},
				}
			);
			return resp.data;
		}
	}
);

export const loadFreeTrial = createAsyncThunk(
	"freeTrial/loadFreeTrial",
	async ({ page, limit }) => {
		const resp = await axios.get(
			"https://api.admin.gsocks.net/proxy/trial",
			{
				headers: {
					authorization: `session ${localStorage.getItem("token")}`,
				},
				params: {
					page,
					limit,
				},
			}
		);
		return resp.data;
	}
);

export const searchFreeTrial = createAsyncThunk(
	"freeTrial/searchFreeTrial",
	async ({ page, limit, searchText }) => {
		const resp = await axios.get(
			"https://api.admin.gsocks.net/proxy/trial",
			{
				headers: {
					authorization: `session ${localStorage.getItem("token")}`,
				},
				params: {
					page,
					limit,
					filter: searchText,
				},
			}
		);
		return resp.data;
	}
);

export const freeTrialSlice = createSlice({
	name: "freeTrial",
	initialState,
	reducers: {
		setStateLimit: (state, action) => {
			state.limit = Number(action.payload);
		},
		setStatePage: (state, action) => {
			state.page = Number(action.payload);
		},
		setDefaultState: (state, action) => {
			state.stateAddGb = "idle";
		},
	},
	extraReducers: (build) => {
		build.addCase(loadFreeTrial.fulfilled, (state, action) => {
			state.count = action.payload.count;
			if (state.count <= 0) state.count = 1;
			state.trials = action.payload.trials;
			state.pages = Math.ceil(state.count / state.limit);
			if (state.pages <= 0) state.pages = 1;
			state.stateLoad = "success";
		});
		build.addCase(loadFreeTrial.rejected, (state, action) => {
			state.stateLoad = "error";
		});
		build.addCase(searchFreeTrial.fulfilled, (state, action) => {
			state.count = action.payload.count;
			if (state.count <= 0) state.count = 1;
			state.trials = action.payload.trials;
			state.pages = Math.ceil(state.count / state.limit);
			if (state.pages <= 0) state.pages = 1;
			state.stateLoad = "success";
		});
		build.addCase(addGb.fulfilled, (state, action) => {
			state.stateAddGb = "success";
		});
		build.addCase(addGb.rejected, (state, action) => {
			state.stateAddGb = "error";
		});
	},
});

export const { setStateLimit, setStatePage, setDefaultState } =
	freeTrialSlice.actions;
export default freeTrialSlice.reducer;
