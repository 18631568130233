import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Layout from "./components/Layout";
import "./App.css";
import Users from "./pages/Users/Users";
import Sales from "./pages/Sales/Sales";
import AdvertisingLinks from "./pages/AdvertisingLinks/AdvertisingLinks";
import Affiliate from "./pages/Affiliate/Affiliate";
import Replenishments from "./pages/Replenishments/Replenishments";
import Login from "./pages/Login/Login";
import Proxies from "./pages/Proxies/Proxies";
import ProxiesInfo from "./pages/ProxiesInfo/ProxiesInfo";
import APIProxies from "./pages/API_proxies/APIProxies";
import API from "./pages/API/API";
import Blog from "./pages/Blog/Blog";
import BlogCreate from "./pages/Blog/Create/Create";
import BlogUpdate from "./pages/Blog/Update/Update";
import Statistic from "./pages/Statistic/Statistic";
import FreeTrial from "./pages/FreeTrial/FreeTrial";
import Residential from "./pages/Residential/Residential";
import Mobile from "./pages/Mobile/Mobile";
import DataCenter from "./pages/DataCenter/DataCenter";
import Private from "./pages/Private/Private";
import Promocode from "./pages/Promocode/Promocode";

const App = () => {
	return (
		<Routes>
			<Route path='/login' element={<Login />} />
			<Route path='/' element={<Layout />}>
				<Route index element={<Home />} />
				<Route path='users' element={<Users />} />
				<Route path='proxies' element={<Proxies />} />
				<Route path='proxies_residential' element={<Residential />} />
				<Route path='proxies_mobile' element={<Mobile />} />
				<Route path='proxies_datacenter' element={<DataCenter />} />
				<Route path='proxies_private' element={<Private />} />
				<Route path='api_proxies' element={<APIProxies />} />
				<Route path='sales' element={<Sales />} />
				<Route
					path='advertising_links'
					element={<AdvertisingLinks />}
				/>
				<Route path='affiliate_program' element={<Affiliate />} />
				<Route path='replenishments' element={<Replenishments />} />
				<Route path='api' element={<API />} />
				<Route path='blog' element={<Blog />} />
				<Route path='blog/create' element={<BlogCreate />} />
				<Route path='blog/update/:postId' element={<BlogUpdate />} />
				<Route path='statistic' element={<Statistic />} />
				<Route path='free_trial' element={<FreeTrial />} />
				<Route path='promocode' element={<Promocode />} />
			</Route>
			<Route path='proxies/:id/:type' element={<ProxiesInfo />} />
			<Route path='/login' element={<Login />} />
		</Routes>
	);
};

export default App;
