import React, { useEffect, useState } from "react";
import styles from "./promocode.module.css";
import Split from "../../components/Split/Split";
import CreateIcon from "@mui/icons-material/Create";
import Modal from "../../components/Modal/Modal";
import BlueBtn from "../../components/BlueBtn/BlueBtn";
import OutLineBtn from "../../components/OutLineBtn/OutLineBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuth } from "../../store/slices/userSlice";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteIcon from "@mui/icons-material/Delete";

import {
	loadPromocodes,
	searchPromocode,
	setStateLimit,
	setStatePage,
	changeStatus,
	setStateUpdate,
	toArchive,
	setStateArchive,
} from "../../store/slices/promocodeSlice";
import CheckAuth from "../../components/CheckAuth";
import { createPromocodeAction } from "../../store/slices/promocodeSlice";
import { setStateCreate } from "../../store/slices/promocodeSlice";

const Promocode = () => {
	const [typeLoad, setTypeLoad] = useState("actual");
	const promocodeState = useSelector((store) => store.promocodeSlice);

	const [codePromocode, setCodePromocode] = useState("");
	const [typePromocode, setTypePromocode] = useState("any");
	const [discountPromocode, setDiscountProdocode] = useState();
	const [usePromocode, setUsePromocoide] = useState();
	const [activeModalCreate, setActiveModalCreate] = useState(false);
	const [alertCreateSuccess, setAlertCreateSuccess] = useState(false);

	const createPromocode = () => {
		dispatch(
			createPromocodeAction({
				code: codePromocode,
				type: typePromocode,
				discount: discountPromocode,
				use: usePromocode,
			})
		);
		setCodePromocode("");
		setTypePromocode("");
		setDiscountProdocode("");
		setUsePromocoide("");
	};

	const cancelPromo = () => {
		setActiveModalCreate(false);
		setCodePromocode("");
		setTypePromocode("");
		setDiscountProdocode("");
		setUsePromocoide("");
	};

	useEffect(() => {
		if (promocodeState.stateCreate == "success") {
			goSearch();
			setActiveModalCreate(false);
			dispatch(setStateCreate("idle"));
		} else if (promocodeState.stateCreate == "error") {
			setActiveModalCreate(false);
			dispatch(setStateCreate("idle"));
		}
	}, [promocodeState.stateCreate]);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [activeModalEditSave, setActiveModalEditSave] = useState(false);

	const [timerInput, setTimerInput] = useState(null);

	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	useEffect(() => {
		if (timerInput) {
			clearTimeout(timerInput);
		}

		const newTimeoutId = setTimeout(goSearch, 1500);
		setTimerInput(newTimeoutId);

		return () => {
			clearTimeout(newTimeoutId);
		};
	}, [searchText]);

	useEffect(() => {
		if (timerInput) {
			clearTimeout(timerInput);
		}

		const newTimeoutId = setTimeout(changeLimit, 1000);
		setTimerInput(newTimeoutId);

		return () => {
			clearTimeout(newTimeoutId);
		};
	}, [limit]);

	useEffect(() => {
		if (timerInput) {
			clearTimeout(timerInput);
		}

		const newTimeoutId = setTimeout(changePage, 1000);
		setTimerInput(newTimeoutId);

		return () => {
			clearTimeout(newTimeoutId);
		};
	}, [page]);

	useEffect(() => {
		if (promocodeState.stateUpdate === "success") {
			dispatch(
				loadPromocodes({
					page: promocodeState.page,
					limit: promocodeState.limit,
					type: typeLoad,
				})
			);
			dispatch(setStateUpdate("idle"));
		} else if (promocodeState.stateUpdate == "error") {
			dispatch(setStateUpdate("idle"));
		}
	}, [promocodeState.stateUpdate]);

	useEffect(() => {
		if (promocodeState.stateArchive === "success") {
			dispatch(
				loadPromocodes({
					page: promocodeState.page,
					limit: promocodeState.limit,
					type: typeLoad,
				})
			);
			dispatch(setStateArchive("idle"));
		} else if (promocodeState.stateArchive == "error") {
			dispatch(setStateArchive("idle"));
		}
	}, [promocodeState.stateArchive]);

	useEffect(() => {
		goSearch();
	}, [promocodeState.page, promocodeState.limit]);

	useEffect(() => {
		setPage(1);
		setLimit(10);
		goSearch();
	}, [typeLoad]);

	useEffect(() => {
		if (promocodeState.stateLoad == "error") {
			localStorage.removeItem("token");
			dispatch(setAuth({ auth: false, stateAuth: "error" }));
			navigate("/login");
		}
	}, [promocodeState.stateLoad]);

	const goSearch = () => {
		if (searchText != "") {
			dispatch(
				searchPromocode({
					page: promocodeState.page,
					limit: promocodeState.limit,
					searchText,
					type: typeLoad,
				})
			);
		} else {
			dispatch(
				loadPromocodes({
					page: promocodeState.page,
					limit: promocodeState.limit,
					type: typeLoad,
				})
			);
		}
	};

	const changeLimit = () => {
		if (!limit) {
			setLimit(1);
			dispatch(setStateLimit(1));
		} else if (limit <= 0) {
			setLimit(1);
			dispatch(setStateLimit(1));
		} else {
			dispatch(setStateLimit(limit));
		}
	};

	const changePage = () => {
		if (!page) {
			setPage(1);
			dispatch(setStatePage(1));
		} else if (page > promocodeState.pages) {
			setPage(promocodeState.pages);
			dispatch(setStatePage(promocodeState.pages));
		} else {
			dispatch(setStatePage(page));
		}
	};

	if (promocodeState.stateLoad == "success") {
		return (
			<CheckAuth>
				<div className={styles.users}>
					<h1>Promocode</h1>
					<Split />
					<div className={styles.tableSection}>
						<div className={styles.tableControllers}>
							<BlueBtn
								text={"Create"}
								onClick={() => setActiveModalCreate(true)}
							/>
							<input
								type='text'
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
								placeholder='search'
							/>
						</div>
						<div className={styles.menu}>
							<div
								className={
									typeLoad == "actual"
										? styles.menu__active
										: ""
								}
								onClick={() => setTypeLoad("actual")}
							>
								Actual
							</div>
							<div
								className={
									typeLoad == "archive"
										? styles.menu__active
										: ""
								}
								onClick={() => setTypeLoad("archive")}
							>
								Archive
							</div>
						</div>
						<div className={styles.table}>
							{typeLoad == "actual" ? (
								<>
									<div className={styles.table_row}>
										<div>ID</div>
										<div>Promo</div>
										<div>Discount</div>
										<div>Use</div>
										<div>Date</div>
										<div>Status</div>
										<div>Actions</div>
									</div>
									{promocodeState.links.map((proxy, idx) => (
										<div
											className={styles.table_row}
											key={idx}
										>
											<div>{proxy.id}</div>
											<div>{proxy.code}</div>
											<div>{proxy.discount} %</div>
											<div>
												{proxy.users} /{" "}
												{proxy.activation_limit == 0
													? "unlimited"
													: proxy.activation_limit}
											</div>
											<div>{proxy.create}</div>
											<div>{proxy.status}</div>
											<div
												className={
													styles.table_row__action
												}
											>
												{proxy.status == "Active" ? (
													<RemoveCircleOutlineIcon
														onClick={() =>
															dispatch(
																changeStatus(
																	proxy.id
																)
															)
														}
													/>
												) : (
													<ControlPointIcon
														onClick={() =>
															dispatch(
																changeStatus(
																	proxy.id
																)
															)
														}
													/>
												)}
												<DeleteIcon
													onClick={() =>
														dispatch(
															toArchive(proxy.id)
														)
													}
												/>
											</div>
										</div>
									))}
								</>
							) : (
								<>
									<div className={styles.table_row}>
										<div>ID</div>
										<div>Promo</div>
										<div>Discount</div>
										<div>Use</div>
										<div>Date</div>
										<div>Status</div>
									</div>
									{promocodeState.links.map((proxy, idx) => (
										<div
											className={styles.table_row}
											key={idx}
										>
											<div>{proxy.id}</div>
											<div>{proxy.code}</div>
											<div>{proxy.discount} %</div>
											<div>
												{proxy.users} /{" "}
												{proxy.activation_limit == 0
													? "unlimited"
													: proxy.activation_limit}
											</div>
											<div>{proxy.create}</div>
											<div>Archive</div>
										</div>
									))}
								</>
							)}
						</div>
						<div className={styles.table_pagination}>
							<div className={styles.table_pagination__rows}>
								<span>Rows per page</span>
								<input
									type='number'
									value={limit}
									onChange={(e) => setLimit(e.target.value)}
								/>
							</div>
							<div className={styles.table_pagination__pages}>
								<KeyboardArrowLeftIcon
									onClick={() =>
										setPage((p) => (p == 1 ? 1 : p - 1))
									}
								/>
								<input
									type='number'
									value={page}
									onChange={(e) => setPage(e.target.value)}
								/>
								<span>of</span>
								<span>{promocodeState.pages}</span>
								<KeyboardArrowRightIcon
									onClick={() =>
										setPage((p) =>
											p == promocodeState.pages
												? p
												: p + 1
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<Modal
					active={activeModalCreate}
					off={() => setActiveModalCreate(false)}
				>
					<div
						className={styles.modalEdit}
						onClick={(e) => e.stopPropagation()}
					>
						<h1>Create Promocode</h1>
						<h3>Code</h3>
						<div className={styles.modalEdit__enter}>
							<input
								type='text'
								value={codePromocode}
								onChange={(e) =>
									setCodePromocode(e.target.value)
								}
							/>
						</div>
						<h3>Type</h3>
						<div className={styles.modalEdit__enter}>
							<select
								onChange={(e) =>
									setTypePromocode(e.target.value)
								}
								value={typePromocode}
							>
								<option value='all'>All</option>
								<option value='residential'>Residential</option>
								<option value='new-mobile'>Mobile</option>
								<option value='private'>Private</option>
							</select>
						</div>
						<h3>Discount</h3>
						<div className={styles.modalEdit__enter}>
							<input
								type='number'
								value={discountPromocode}
								onChange={(e) =>
									setDiscountProdocode(e.target.value)
								}
							/>
						</div>
						<h3>Count use</h3>
						<div className={styles.modalEdit__enter}>
							<input
								type='number'
								value={usePromocode}
								onChange={(e) =>
									setUsePromocoide(e.target.value)
								}
							/>
						</div>
						<div className={styles.modalEdit__btns}>
							<OutLineBtn text={"Cancel"} onClick={cancelPromo} />
							<BlueBtn
								text={"Continue"}
								onClick={createPromocode}
							/>
						</div>
					</div>
				</Modal>
			</CheckAuth>
		);
	} else return "";
};

export default Promocode;
