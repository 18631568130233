import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	auth: false,
	stateAuth: "idle",
	token: "",
};

export const loginAction = createAsyncThunk(
	"user/loginAction",
	async (code) => {
		const resp = await axios.post(
			"https://api.admin.gsocks.net/auth/signin",
			{ code }
		);
		const data = await resp.data;
		return data;
	}
);

export const checkAuthAction = createAsyncThunk(
	"user/checkAuthAction",
	async (token) => {
		const resp = await axios.post(
			"https://api.admin.gsocks.net/auth/check",
			{ token }
		);
		const data = await resp.data;
		return [data, token];
	}
);

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setAuth: (state, action) => {
			state.auth = action.payload.auth;
			state.stateAuth = action.payload.stateAuth;
		},
	},
	extraReducers: (build) => {
		build.addCase(loginAction.fulfilled, (state, action) => {
			state.auth = true;
			state.stateAuth = "success";
			state.token = action.payload.token;
			localStorage.setItem("token", action.payload.token);
		});
		build.addCase(loginAction.rejected, (state, action) => {
			state.auth = false;
			state.stateAuth = "error";
			state.token = "";
			localStorage.removeItem("token");
		});
		build.addCase(checkAuthAction.fulfilled, (state, action) => {
			if (action.payload[0]) {
				state.auth = true;
				state.stateAuth = "success";
				localStorage.setItem("token", action.payload[1]);
				state.token = action.payload[1];
			} else {
				state.auth = false;
				state.stateAuth = "error";
				localStorage.removeItem("token");
				state.token = "";
			}
		});
	},
});

export const { setAuth } = userSlice.actions;
export default userSlice.reducer;
