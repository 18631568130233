import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	affiliates: [],
	count: 0,
	page: 1,
	limit: 10,
	pages: 1,
	stateLoad: "idle",
	stateUpdate: "idle",
};

export const loadProxy = createAsyncThunk(
	"proxy/loadProxy",
	async ({ page, limit, type, status }) => {
		if (localStorage.getItem("token")) {
			if (type == "api") {
				const resp = await axios.get(
					"https://api.admin.gsocks.net/proxy",
					{
						headers: {
							authorization: `session ${localStorage.getItem(
								"token"
							)}`,
						},
						params: {
							type: "new-mobile",
							active:
								status == "Success"
									? "active"
									: status.toLowerCase(),
						},
					}
				);
				return resp.data;
			} else {
				const resp = await axios.get(
					"https://api.admin.gsocks.net/proxy",
					{
						headers: {
							authorization: `session ${localStorage.getItem(
								"token"
							)}`,
						},
						params: {
							type: "new-mobile",
							page,
							limit,
							active:
								status == "Success"
									? "active"
									: status.toLowerCase(),
						},
					}
				);
				return resp.data;
			}
		}
	}
);

export const updateProxy = createAsyncThunk(
	"proxy/updateProxy",
	async (data) => {
		const resp = await axios.post(
			`https://api.admin.gsocks.net/proxy/update-note/${data.id}`,
			{ note: data.note, type: data.type },
			{
				headers: {
					authorization: `session ${localStorage.getItem("token")}`,
				},
			}
		);
		return resp.data;
	}
);

export const searchProxy = createAsyncThunk(
	"proxy/searchProxy",
	async ({ page, limit, searchText, order, type, status }) => {
		if (!type) {
			const resp = await axios.get("https://api.admin.gsocks.net/proxy", {
				headers: {
					authorization: `session ${localStorage.getItem("token")}`,
				},
				params: {
					page,
					limit,
					filter: searchText,
					type: "new-mobile",
					active:
						status == "Success" ? "active" : status.toLowerCase(),
				},
			});
			return resp.data;
		} else {
			const resp = await axios.get(
				"https://api.admin.gsocks.net/proxy/proxy-api",
				{
					headers: {
						authorization: `session ${localStorage.getItem(
							"token"
						)}`,
					},
					params: {
						type: "new-mobile",
						page,
						limit,
						filter: searchText,
						active:
							status == "Success"
								? "active"
								: status.toLowerCase(),
					},
				}
			);
			return resp.data;
		}
	}
);

export const mobileSlice = createSlice({
	name: "proxy",
	initialState,
	reducers: {
		setStateUpdate: (state, action) => {
			state.stateUpdate = action.payload;
		},
		setStateLimit: (state, action) => {
			state.limit = Number(action.payload);
		},
		setStatePage: (state, action) => {
			state.page = Number(action.payload);
		},
	},
	extraReducers: (build) => {
		build.addCase(loadProxy.fulfilled, (state, action) => {
			if (action.payload) {
				state.count = action.payload.count;
				state.proxies = action.payload.proxies;
				state.pages = Math.ceil(state.count / state.limit);
				state.stateLoad = "success";
			}
		});
		build.addCase(loadProxy.rejected, (state, action) => {
			state.stateLoad = "error";
		});
		build.addCase(searchProxy.fulfilled, (state, action) => {
			state.count = action.payload.count;
			state.proxies = action.payload.proxies;
			state.stateLoad = "success";
		});
		build.addCase(updateProxy.fulfilled, (state, action) => {
			state.stateUpdate = "success";
		});
		build.addCase(updateProxy.rejected, (state, action) => {
			state.stateUpdate = "success";
		});
	},
});

export const { setStateUpdate, setStateLimit, setStatePage } =
	mobileSlice.actions;
export default mobileSlice.reducer;
